<template>
    <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">

            <div class="text-center">
                <i class="fa fa-exclamation-triangle fa-8x text-yellow-500 mb-8" aria-hidden="true"></i>
                <div class="text-7xl text-yellow-500 font-bold mb-5">404</div>
                <div class="text-base font-medium">Halaman yang Anda cari tidak ada</div>
            </div>

        </div>
    </div>
</template>

<script>

</script>
